<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<symbol id="lock" viewBox="0 0 512 512">
			<path
				d="m436.75 180.75h-45.25v-45.25c0-74.715-60.785-135.5-135.5-135.5s-135.5 60.785-135.5 135.5v45.25h-45.25c-8.284 0-15 6.716-15 15v301.25c0 8.284 6.716 15 15 15h361.5c8.284 0 15-6.716 15-15v-301.25c0-8.284-6.716-15-15-15zm-135.5 0h-90.5v-45.25c0-24.951 20.299-45.25 45.25-45.25s45.25 20.299 45.25 45.25zm-150.75-45.25c0-58.173 47.327-105.5 105.5-105.5s105.5 47.327 105.5 105.5v45.25h-30.25v-45.25c0-41.493-33.757-75.25-75.25-75.25s-75.25 33.757-75.25 75.25v45.25h-30.25zm271.25 346.5h-331.5v-271.25h331.5z"
			/>
			<path
				d="m241 360.739v43.948c0 8.284 6.716 15 15 15s15-6.716 15-15v-43.948c17.529-6.198 30.125-22.927 30.125-42.552 0-24.882-20.243-45.125-45.125-45.125s-45.125 20.243-45.125 45.125c0 19.625 12.596 36.355 30.125 42.552zm15-57.677c8.34 0 15.125 6.785 15.125 15.125s-6.785 15.125-15.125 15.125-15.125-6.785-15.125-15.125 6.785-15.125 15.125-15.125z"
			/>
		</symbol>

		<symbol id="apple-logo" viewBox="0 0 512 512">
			<g>
				<path
					d="M185.255,512c-76.201-0.439-139.233-155.991-139.233-235.21c0-129.404,97.075-157.734,134.487-157.734   c16.86,0,34.863,6.621,50.742,12.48c11.104,4.087,22.588,8.306,28.975,8.306c3.823,0,12.832-3.589,20.786-6.738   c16.963-6.753,38.071-15.146,62.651-15.146c0.044,0,0.103,0,0.146,0c18.354,0,74.004,4.028,107.461,54.272l7.837,11.777   l-11.279,8.511c-16.113,12.158-45.513,34.336-45.513,78.267c0,52.031,33.296,72.041,49.292,81.665   c7.061,4.248,14.37,8.628,14.37,18.208c0,6.255-49.922,140.566-122.417,140.566c-17.739,0-30.278-5.332-41.338-10.034   c-11.191-4.761-20.845-8.862-36.797-8.862c-8.086,0-18.311,3.823-29.136,7.881C221.496,505.73,204.752,512,185.753,512H185.255z"
				/>
				<path
					d="M351.343,0c1.888,68.076-46.797,115.304-95.425,112.342C247.905,58.015,304.54,0,351.343,0z"
				/>
			</g>
		</symbol>

		<symbol id="paper-plane" viewBox="0 0 24 24">
			<path
				d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z"
			/>
			<path
				d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z"
			/>
		</symbol>

		<symbol id="warning" viewBox="0 0 511.999 511.999">
			<g>
				<path
					d="M506.175,365.576c-4.095-10.258-15.729-15.255-25.987-11.16c-10.258,4.095-15.255,15.729-11.16,25.987
                    c5.206,13.043,3.499,27.935-4.567,39.839c-8.07,11.91-20.991,18.741-35.449,18.741H83.068c-15.771,0-29.776-8.229-37.465-22.015
                    c-7.701-13.809-7.339-30.082,0.968-43.533L219.543,93.382c7.876-12.752,21.519-20.365,36.497-20.365
                    c14.978,0,28.62,7.613,36.497,20.365l127.927,207.124c5.804,9.397,18.128,12.312,27.523,6.505
                    c9.396-5.803,12.31-18.126,6.505-27.523L326.564,72.364c-15.216-24.636-41.58-39.344-70.525-39.344s-55.309,14.709-70.525,39.344
                    L12.542,352.418c-16.036,25.966-16.734,57.38-1.869,84.033c14.853,26.63,41.916,42.528,72.395,42.528h345.944
                    c27.938,0,52.927-13.233,68.56-36.303C513.088,419.778,516.303,390.955,506.175,365.576z"
				/>
				<path
					d="M256.049,399.987c11.045,0,19.998-8.953,19.998-19.998c0-11.045-8.953-19.998-19.998-19.998h-0.01
                    c-11.045,0-19.993,8.953-19.993,19.998C236.046,391.034,245.004,399.987,256.049,399.987z"
				/>
				<path
					d="M276.038,309.995V163.008c0-11.045-8.953-19.998-19.998-19.998c-11.045,0-19.998,8.953-19.998,19.998v146.987
                    c0,11.045,8.953,19.998,19.998,19.998C267.085,329.993,276.038,321.04,276.038,309.995z"
				/>
			</g>
		</symbol>

		<symbol id="save" viewBox="0 0 512.007 512.007">
			<path
				d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z"
			/>
		</symbol>

		<symbol id="qrcode" viewBox="0 0 513.37 513.37">
			<path
				d="m497.691 0h-120.8c-8.284 0-15 6.716-15 15s6.716 15 15 15h105.801v114.818c0 8.284 6.716 15 15 15s15-6.716 15-15v-129.818c-.001-8.284-6.716-15-15.001-15z"
			/>
			<path
				d="m15.77 150.801c8.284 0 15-6.716 15-15v-105.801h114.818c8.284 0 15-6.716 15-15s-6.716-15-15-15h-129.818c-8.284 0-15 6.716-15 15v120.801c0 8.284 6.715 15 15 15z"
			/>
			<path
				d="m136.479 483.37h-105.8v-114.818c0-8.284-6.716-15-15-15s-15 6.716-15 15v129.818c0 8.284 6.716 15 15 15h120.801c8.284 0 15-6.716 15-15s-6.716-15-15.001-15z"
			/>
			<path
				d="m497.601 362.569c-8.284 0-15 6.716-15 15v105.801h-114.819c-8.284 0-15 6.716-15 15s6.716 15 15 15h129.818c8.284 0 15-6.716 15-15v-120.801c.001-8.284-6.715-15-14.999-15z"
			/>
			<path
				d="m196.857 297.962h-121.227c-8.284 0-15 6.716-15 15v124.008c0 8.284 6.716 15 15 15h121.228c8.284 0 15-6.716 15-15v-124.008c-.001-8.284-6.716-15-15.001-15zm-15 124.008h-91.227v-94.008h91.228v94.008z"
			/>
			<path
				d="m196.857 240.915h-32.166c-8.284 0-15 6.716-15 15s6.716 15 15 15h32.166c8.284 0 15-6.716 15-15s-6.715-15-15-15z"
			/>
			<path
				d="m75.63 270.915h31.848c8.284 0 15-6.716 15-15s-6.716-15-15-15h-31.848c-8.284 0-15 6.716-15 15s6.716 15 15 15z"
			/>
			<path
				d="m256.742 213.684c8.284 0 15-6.716 15-15v-124.008c0-8.284-6.716-15-15-15s-15 6.716-15 15v124.008c0 8.284 6.716 15 15 15z"
			/>
			<path
				d="m453.074 436.97v-71.666c0-8.284-6.716-15-15-15h-73.677v-26.062c0-8.284-6.716-15-15-15h-77.655v-38.327h113.232v20.925c0 8.284 6.716 15 15 15s15-6.716 15-15v-20.925h23.1c8.284 0 15-6.716 15-15s-6.716-15-15-15h-181.332c-8.284 0-15 6.716-15 15v180.368c0 8.284 6.716 15 15 15s15-6.716 15-15v-37.81h24.283c8.284 0 15-6.716 15-15s-6.716-15-15-15h-24.283v-29.231h62.655v26.062c0 8.284 6.716 15 15 15h73.677v41.666h-112.912c-8.284 0-15 6.716-15 15s6.716 15 15 15h127.912c8.284 0 15-6.716 15-15z"
			/>
			<path
				d="m196.857 59.676h-121.227c-8.284 0-15 6.716-15 15v124.008c0 8.284 6.716 15 15 15h121.228c8.284 0 15-6.716 15-15v-124.008c-.001-8.284-6.716-15-15.001-15zm-15 124.008h-91.227v-94.008h91.228v94.008z"
			/>
			<path
				d="m316.848 213.684h121.227c8.284 0 15-6.716 15-15v-124.008c0-8.284-6.716-15-15-15h-121.227c-8.284 0-15 6.716-15 15v124.008c0 8.284 6.715 15 15 15zm15-124.008h91.227v94.008h-91.227z"
			/>
			<path
				d="m124.692 394.927h23.104c4.397 0 7.962-3.565 7.962-7.962v-24c0-4.397-3.565-7.962-7.962-7.962h-23.104c-4.397 0-7.962 3.565-7.962 7.962v24c0 4.397 3.565 7.962 7.962 7.962z"
			/>
			<path
				d="m124.692 156.641h23.104c4.397 0 7.962-3.565 7.962-7.962v-23.999c0-4.397-3.565-7.962-7.962-7.962h-23.104c-4.397 0-7.962 3.565-7.962 7.962v23.999c0 4.398 3.565 7.962 7.962 7.962z"
			/>
			<path
				d="m389.013 117.359h-23.104c-4.397 0-7.962 3.565-7.962 7.962v23.999c0 4.397 3.565 7.962 7.962 7.962h23.104c4.397 0 7.962-3.565 7.962-7.962v-23.999c0-4.397-3.565-7.962-7.962-7.962z"
			/>
		</symbol>

		<symbol id="person" viewBox="0 0 480.05 480.05">
			<path
				d="m394.025 424.79v39.26c0 4.42-3.58 8-8 8h-292c-4.42 0-8-3.58-8-8v-39.26c0-41.19 33.39-74.56 74.59-74.57 14.56-.01 27.38-7.5 34.76-18.86 7.414-11.394 6.65-21.302 6.65-29.31l.15-.37c-35.9-14.86-61.15-50.23-61.15-91.5v-3.13c-14.255 0-25-11.265-25-24.54v-41.56c-.32-14.47.34-65.5 37.2-101.03 42.86-41.31 110.78-37.93 159.98-15.83 1.6.72 1.55 3.01-.07 3.68l-12.83 5.28c-1.92.79-1.51 3.62.55 3.84l6.23.67c33.52 3.58 61.41 23.59 75.32 47.3.13.23.09.53-.1.73-11.08 11.3-17.28 26.51-17.28 42.35v53.12c0 14.33-11.61 25.99-26 25.99v3.13c0 41.27-25.25 76.64-61.15 91.5l.15.37c0 7.781-.826 17.821 6.65 29.31 7.38 11.36 20.2 18.85 34.76 18.86 41.2.01 74.59 33.38 74.59 74.57z"
				fill="#d1e7ff"
			/>
			<path
				d="m382.401 84.859c.133.227.093.531-.101.729-11.073 11.304-17.275 26.516-17.275 42.352v53.12c0 14.329-11.607 25.99-26 25.99 0-30.261.017-46.074.011-54.575-.004-5.367-5.189-9.224-10.318-7.646-16.122 4.96-34.928 7.516-54.692 3.221-34.78-7.56-44.63-21.19-73-28-31.7-7.6-60.45 16.68-60.25 47.74.06 10.21.23 22.97.25 39.26-14.255 0-25-11.265-25-24.54v-41.56c-.32-14.47.34-65.5 37.2-101.03 42.856-41.311 110.783-37.929 159.975-15.827 1.601.719 1.558 3.01-.065 3.678l-12.831 5.282c-1.918.79-1.514 3.617.548 3.838l6.232.669c33.523 3.584 61.411 23.587 75.316 47.299z"
				fill="#b9dcff"
			/>
			<path
				d="m328.717 144.829c5.129-1.578 10.314 2.275 10.318 7.641.007 8.795-.011 25.41-.011 57.71 0 41.27-25.25 76.64-61.15 91.5-64.917 26.947-136.85-20.924-136.85-91.5 0-18.251-.184-31.218-.25-42.39-.2-31.06 28.55-55.34 60.25-47.74 28.37 6.81 38.22 20.44 73 28 19.765 4.295 38.572 1.739 54.693-3.221z"
				fill="#d1e7ff"
			/>
			<path
				d="m394.025 424.79v39.26c0 4.418-3.582 8-8 8h-292c-4.418 0-8-3.582-8-8v-39.26c0-41.19 33.395-74.555 74.585-74.57 14.564-.005 27.387-7.504 34.765-18.86 25.754 22.002 63.531 22.015 89.3 0 7.377 11.356 20.201 18.855 34.765 18.86 41.19.015 74.585 33.38 74.585 74.57z"
				fill="#b9dcff"
			/>
			<path
				d="m200.907 374.377c-3.698 1.163-7.664 1.804-11.916 1.841-41.296.364-74.966 33.017-74.966 74.315v7.517c0 7.732-6.268 14-14 14h-6c-4.418 0-8-3.582-8-8v-39.26c0-41.191 33.395-74.555 74.585-74.57 14.564-.005 27.387-7.504 34.765-18.86 2.974 2.54 6.158 4.823 9.512 6.822 14.753 8.791 12.402 31.044-3.98 36.195z"
				fill="#d1e7ff"
			/>
			<path
				d="m279.143 374.377c3.698 1.163 7.664 1.804 11.916 1.841 41.296.364 74.966 33.017 74.966 74.315v7.517c0 7.732 6.268 14 14 14h6c4.418 0 8-3.582 8-8v-39.26c0-41.191-33.395-74.555-74.585-74.57-14.564-.005-27.387-7.504-34.765-18.86-2.974 2.54-6.158 4.823-9.512 6.822-14.752 8.791-12.401 31.044 3.98 36.195z"
				fill="#a2d0ff"
			/>
			<path
				d="m279.155 318.47c-24.637 10.313-51.712 11.113-78.26 0 1.356-5.626 1.13-9.27 1.13-16.42l.15-.37c24.082 9.996 51.571 10.016 75.7 0l.15.37c0 7.153-.225 10.796 1.13 16.42z"
				fill="#b9dcff"
			/>
			<path
				d="m313.135 27.77c-9.428 3.877-11.515 5.243-15.845 4.53-79.347-13.062-141.827 28.426-150.375 109.499-.15 1.423-.515 2.82-1.146 4.104-6.085 12.388-5.003 21.556-4.874 36.798.135 16.035-24.029 17.062-24.839 1.047-.051-1-.031 1.636-.031-42.798-.32-14.47.34-65.5 37.2-101.03 42.86-41.31 110.78-37.93 159.98-15.83 1.6.72 1.55 3.01-.07 3.68z"
				fill="#d1e7ff"
			/>
			<path
				d="m217.622 274.119c16.271 10.175 3.43 35.144-14.38 27.997-36.577-14.679-62.216-50.34-62.216-91.935 0-18.273-.184-31.174-.25-42.39-.063-10.041 2.896-19.372 8.002-27.146 6.418-9.771 21.913-5.513 21.998 7.146.066 11.254.25 24.098.25 42.39-.001 36.497 19.187 66.797 46.596 83.938z"
				fill="#e8f3ff"
			/>
			<path
				d="m402.025 424.793v47.257c0 4.418-3.582 8-8 8s-8-3.582-8-8v-47.257c0-36.795-29.775-66.571-66.573-66.571-17.411 0-33.209-8.87-42.258-23.729-2.299-3.773-1.103-8.695 2.671-10.993 3.771-2.301 8.694-1.104 10.993 2.671 6.122 10.051 16.812 16.051 28.594 16.051 45.638-.002 82.573 36.93 82.573 82.571zm-149.125-86.306c-16.747 3.65-33.745-.054-47.23-9.313 2.313-5.118 3.734-10.625 4.185-16.331 68.573 20.185 137.17-31.57 137.17-102.663 0-67.85.083-66.77-.192-69.552-1.053-11.027-16.986-9.527-15.923 1.569.179 1.814.115 3.401.115 67.982 0 50.178-40.822 91-91 91s-91-40.822-91-91c0-18.022-.192-31.978-.255-42.443-.162-26.255 24.117-46.205 50.39-39.907 37.054 8.885 51.988 31.997 99.482 29.785 4.413-.207 7.823-3.952 7.616-8.366-.207-4.413-3.929-7.8-8.366-7.616-42.816 2.013-54.684-19.692-95.001-29.361-36.452-8.744-70.344 18.99-70.121 55.564.054 8.506.166 17.822.222 29.296-5.326-2.777-8.968-8.285-8.968-14.621 0-.183.001-41.554-.002-41.736-.401-18.133 2.182-63.696 34.754-95.094 36.391-35.078 93.328-35.091 137.598-19.66l-6.395 2.632c-7.635 3.141-6.039 14.47 2.193 15.352l14.062 1.506c27.064 2.898 50.998 17.011 66.332 38.941-10.064 12.229-15.542 27.451-15.542 43.488v54.57c0 4.418 3.582 8 8 8s8-3.582 8-8v-54.57c0-13.903 5.369-27.007 15.118-36.896 2.714-2.75 3.047-7.188 1.154-10.243-15.95-25.579-39.684-42.049-66.771-48.655 4.382-3.738 3.52-10.683-1.552-13.248-23.257-11.747-56.433-19.993-88.005-18.778-79.872 3.096-126.627 62.746-124.942 140.92v41.472c0 15.305 10.708 28.167 25.081 31.621 1.491 40.861 25.999 75.947 60.919 92.644v2.105c0 18.444-14.945 33.342-33.427 33.342-45.733 0-82.573 37.021-82.573 82.571v47.257c0 4.418 3.582 8 8 8s8-3.582 8-8v-47.257c0-36.793 29.775-66.571 66.573-66.571 13.623 0 26.596-5.46 36.192-15.744 17.055 11.641 38.469 16.229 59.516 11.642 4.317-.94 7.054-5.203 6.113-9.52-.941-4.318-5.206-7.058-9.52-6.114zm68.125 100.563h-24c-4.418 0-8 3.582-8 8s3.582 8 8 8h24c4.418 0 8-3.582 8-8s-3.582-8-8-8z"
				fill="#2e58ff"
			/>
		</symbol>

		<symbol id="refresh" viewBox="0 0 491.236 491.236">
			<path
				d="M55.89,262.818c-3-26-0.5-51.1,6.3-74.3c22.6-77.1,93.5-133.8,177.6-134.8v-50.4c0-2.8,3.5-4.3,5.8-2.6l103.7,76.2
			c1.7,1.3,1.7,3.9,0,5.1l-103.6,76.2c-2.4,1.7-5.8,0.2-5.8-2.6v-50.3c-55.3,0.9-102.5,35-122.8,83.2c-7.7,18.2-11.6,38.3-10.5,59.4
			c1.5,29,12.4,55.7,29.6,77.3c9.2,11.5,7,28.3-4.9,37c-11.3,8.3-27.1,6-35.8-5C74.19,330.618,59.99,298.218,55.89,262.818z
			 M355.29,166.018c17.3,21.5,28.2,48.3,29.6,77.3c1.1,21.2-2.9,41.3-10.5,59.4c-20.3,48.2-67.5,82.4-122.8,83.2v-50.3
			c0-2.8-3.5-4.3-5.8-2.6l-103.7,76.2c-1.7,1.3-1.7,3.9,0,5.1l103.6,76.2c2.4,1.7,5.8,0.2,5.8-2.6v-50.4
			c84.1-0.9,155.1-57.6,177.6-134.8c6.8-23.2,9.2-48.3,6.3-74.3c-4-35.4-18.2-67.8-39.5-94.4c-8.8-11-24.5-13.3-35.8-5
			C348.29,137.718,346.09,154.518,355.29,166.018z"
			/>
		</symbol>

		<symbol id="copy" viewBox="-40 0 512 512">
			<path
				d="m271 512h-191c-44.113281 0-80-35.886719-80-80v-271c0-44.113281 35.886719-80 80-80h191c44.113281 0 80 35.886719 80 80v271c0 44.113281-35.886719 80-80 80zm-191-391c-22.054688 0-40 17.945312-40 40v271c0 22.054688 17.945312 40 40 40h191c22.054688 0 40-17.945312 40-40v-271c0-22.054688-17.945312-40-40-40zm351 261v-302c0-44.113281-35.886719-80-80-80h-222c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h222c22.054688 0 40 17.945312 40 40v302c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm0 0"
			/>
		</symbol>

		<symbol id="download" viewBox="0 0 512 512">
			<g>
				<g>
					<path
						d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
                        c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
                        c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z"
					/>
				</g>
			</g>
		</symbol>

		<symbol id="stop" viewBox="0 0 64 64">
			<path d="M49,24.5A2.5,2.5,0,0,0,46.5,22H45v5h1.5A2.5,2.5,0,0,0,49,24.5Z" />
			<path d="M28,62h8c0-1.36,0-2.31,0-3H28Z" />
			<rect x="26" y="55" width="12" height="2" />
			<path d="M37,22a2,2,0,0,0-2,2v8a2,2,0,0,0,4,0V24A2,2,0,0,0,37,22Z" />
			<path
				d="M60,24.93,46.66,4.7A4,4,0,0,0,43.39,3H20.61a4,4,0,0,0-3.28,1.72L4,24.91a5.38,5.38,0,0,0,0,6.16L17.34,51.3A4,4,0,0,0,20.61,53H43.39a4,4,0,0,0,3.28-1.72L60,31.09a5.38,5.38,0,0,0,0-6.16Zm-1.2,3.61-14,22A1,1,0,0,1,44,51H20a1,1,0,0,1-.84-.46l-14-22a1,1,0,0,1,0-1.08l14-22A1,1,0,0,1,20,5H44a1,1,0,0,1,.84.46l14,22A1,1,0,0,1,58.84,28.54Z"
			/>
			<path
				d="M20.55,7,7.19,28,20.55,49h22.9L56.81,28,43.45,7ZM23,25H21V24a2,2,0,0,0-4,0,2.74,2.74,0,0,0,1.22,2.28l2.67,1.78A4.72,4.72,0,0,1,23,32a4,4,0,0,1-8,0V31h2v1a2,2,0,0,0,4,0,2.74,2.74,0,0,0-1.22-2.28l-2.67-1.78A4.72,4.72,0,0,1,15,24a4,4,0,0,1,8,0Zm9-3H29V36H27V22H24V20h8Zm9,10a4,4,0,0,1-8,0V24a4,4,0,0,1,8,0Zm5.5-12a4.5,4.5,0,0,1,0,9H45v7H43V21a1,1,0,0,1,1-1Z"
			/>
		</symbol>

		<symbol id="undo" viewBox="0 0 512.011 512.011">
			<g>
				<path
					d="M511.136,286.255C502.08,194.863,419.84,128.015,328,128.015H192v-80c0-6.144-3.52-11.744-9.056-14.432
                        c-5.568-2.656-12.128-1.952-16.928,1.92l-160,128C2.208,166.575,0,171.151,0,176.015s2.208,9.44,5.984,12.512l160,128
                        c2.912,2.304,6.464,3.488,10.016,3.488c2.368,0,4.736-0.512,6.944-1.568c5.536-2.688,9.056-8.288,9.056-14.432v-80h139.392
                        c41.856,0,80,30.08,84.192,71.712c4.832,47.872-32.704,88.288-79.584,88.288H208c-8.832,0-16,7.168-16,16v64
                        c0,8.832,7.168,16,16,16h128C438.816,480.015,521.472,391.151,511.136,286.255z"
				/>
			</g>
		</symbol>

		<symbol id="search" viewBox="0 0 53.62 53.62">
			<path
				d="M53.627,49.385L37.795,33.553C40.423,30.046,42,25.709,42,21C42,9.42,32.58,0,21,0S0,9.42,0,21s9.42,21,21,21
	c4.709,0,9.046-1.577,12.553-4.205l15.832,15.832L53.627,49.385z M2,21C2,10.523,10.523,2,21,2s19,8.523,19,19s-8.523,19-19,19
	S2,31.477,2,21z"
			/>
		</symbol>

		<symbol id="switch" viewBox="0 0 24 24">
			<path
				d="m19.31 5.26c-.175 0-.352-.061-.494-.186-1.893-1.66-4.314-2.574-6.816-2.574-2.358 0-4.664.827-6.494 2.33-.321.263-.794.216-1.056-.104s-.216-.793.104-1.056c2.097-1.722 4.742-2.67 7.446-2.67 2.866 0 5.638 1.046 7.805 2.946.311.273.342.747.069 1.059-.148.168-.356.255-.564.255z"
			/>
			<path
				d="m7.25 5h-2.5c-.414 0-.75-.336-.75-.75v-2.5c0-.303.183-.577.463-.693s.603-.052.817.163l2.5 2.5c.214.214.279.537.163.817s-.39.463-.693.463z"
			/>
			<path
				d="m12.12 23c-2.867 0-5.639-1.046-7.805-2.946-.312-.273-.342-.747-.069-1.059.273-.311.747-.342 1.059-.069 1.892 1.66 4.312 2.574 6.815 2.574 2.358 0 4.664-.827 6.494-2.33.32-.264.792-.217 1.056.104.263.32.216.793-.104 1.056-2.097 1.722-4.742 2.67-7.446 2.67z"
			/>
			<path
				d="m19.25 23c-.195 0-.387-.076-.53-.22l-2.5-2.5c-.214-.214-.279-.537-.163-.817s.39-.463.693-.463h2.5c.414 0 .75.336.75.75v2.5c0 .303-.183.577-.463.693-.093.038-.19.057-.287.057z"
			/>
			<circle cx="5" cy="9.5" r="2.5" />
			<path
				d="m7.25 13h-4.5c-1.517 0-2.75 1.233-2.75 2.75v.5c0 .414.336.75.75.75h8.5c.414 0 .75-.336.75-.75v-.5c0-1.517-1.233-2.75-2.75-2.75z"
			/>
			<circle cx="19" cy="9.5" r="2.5" />
			<path
				d="m21.25 13h-4.5c-1.517 0-2.75 1.233-2.75 2.75v.5c0 .414.336.75.75.75h8.5c.414 0 .75-.336.75-.75v-.5c0-1.517-1.233-2.75-2.75-2.75z"
			/>
		</symbol>

		<symbol id="edit" viewBox="0 0 477.873 477.873">
			<g>
				<g>
					<path
						d="M392.533,238.937c-9.426,0-17.067,7.641-17.067,17.067V426.67c0,9.426-7.641,17.067-17.067,17.067H51.2
                        c-9.426,0-17.067-7.641-17.067-17.067V85.337c0-9.426,7.641-17.067,17.067-17.067H256c9.426,0,17.067-7.641,17.067-17.067
                        S265.426,34.137,256,34.137H51.2C22.923,34.137,0,57.06,0,85.337V426.67c0,28.277,22.923,51.2,51.2,51.2h307.2
                        c28.277,0,51.2-22.923,51.2-51.2V256.003C409.6,246.578,401.959,238.937,392.533,238.937z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M458.742,19.142c-12.254-12.256-28.875-19.14-46.206-19.138c-17.341-0.05-33.979,6.846-46.199,19.149L141.534,243.937
                        c-1.865,1.879-3.272,4.163-4.113,6.673l-34.133,102.4c-2.979,8.943,1.856,18.607,10.799,21.585
                        c1.735,0.578,3.552,0.873,5.38,0.875c1.832-0.003,3.653-0.297,5.393-0.87l102.4-34.133c2.515-0.84,4.8-2.254,6.673-4.13
                        l224.802-224.802C484.25,86.023,484.253,44.657,458.742,19.142z M434.603,87.419L212.736,309.286l-66.287,22.135l22.067-66.202
                        L390.468,43.353c12.202-12.178,31.967-12.158,44.145,0.044c5.817,5.829,9.095,13.72,9.12,21.955
                        C443.754,73.631,440.467,81.575,434.603,87.419z"
					/>
				</g>
			</g>
		</symbol>

		<symbol id="check" viewBox="0 -46 417.81333 417">
			<path
				d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
			/>
		</symbol>

		<symbol id="upload-image" viewBox="0 -18 512 512">
			<path
				d="m432 0h-352c-44.113281 0-80 35.886719-80 80v280c0 44.113281 35.886719 80 80 80h273c11.046875 0 20-8.953125 20-20s-8.953125-20-20-20h-73.664062l-45.984376-59.65625 145.722657-185.347656 98.097656 108.421875c5.546875 6.136719 14.300781 8.21875 22.019531 5.246093 7.714844-2.976562 12.808594-10.394531 12.808594-18.664062v-170c0-44.113281-35.886719-80-80-80zm40 198.085938-79.167969-87.503907c-3.953125-4.371093-9.640625-6.785156-15.523437-6.570312-5.886719.207031-11.386719 2.996093-15.03125 7.628906l-154.117188 196.023437-52.320312-67.875c-3.785156-4.910156-9.636719-7.789062-15.839844-7.789062-.003906 0-.007812 0-.011719 0-6.203125.003906-12.058593 2.886719-15.839843 7.804688l-44.015626 57.21875c-6.734374 8.757812-5.097656 21.3125 3.65625 28.046874 8.757813 6.738282 21.3125 5.097657 28.050782-3.65625l28.175781-36.632812 88.816406 115.21875h-148.832031c-22.054688 0-40-17.945312-40-40v-280c0-22.054688 17.945312-40 40-40h352c22.054688 0 40 17.945312 40 40zm0 0"
			/>
			<path
				d="m140 72c-33.085938 0-60 26.914062-60 60s26.914062 60 60 60 60-26.914062 60-60-26.914062-60-60-60zm0 80c-11.027344 0-20-8.972656-20-20s8.972656-20 20-20 20 8.972656 20 20-8.972656 20-20 20zm0 0"
			/>
			<path
				d="m468.476562 302.941406c-.058593-.058594-.117187-.121094-.175781-.179687-9.453125-9.519531-22.027343-14.761719-35.410156-14.761719-13.34375 0-25.882813 5.210938-35.324219 14.675781l-38.613281 38.085938c-7.863281 7.753906-7.949219 20.417969-.191406 28.28125 7.753906 7.863281 20.417969 7.953125 28.28125.195312l25.847656-25.492187v112.253906c0 11.046875 8.953125 20 20 20s20-8.953125 20-20v-111.644531l24.738281 25.554687c3.921875 4.054688 9.144532 6.089844 14.371094 6.089844 5.011719 0 10.027344-1.871094 13.910156-5.628906 7.9375-7.683594 8.140625-20.34375.457032-28.28125zm0 0"
			/>
		</symbol>

		<symbol id="cross" viewBox="0 0 384 384">
			<g>
				<g>
					<path
						d="M368,176c-8.832,0-16,7.168-16,16c0,88.224-71.776,160-160,160S32,280.224,32,192S103.776,32,192,32
                        c42.952,0,83.272,16.784,113.536,47.264c6.224,6.264,16.36,6.304,22.624,0.08c6.272-6.224,6.304-16.36,0.08-22.632
                        C291.928,20.144,243.536,0,192,0C86.128,0,0,86.128,0,192s86.128,192,192,192c105.864,0,192-86.128,192-192
                        C384,183.168,376.832,176,368,176z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path
						d="M214.624,192l36.688-36.688c6.248-6.248,6.248-16.376,0-22.624s-16.376-6.248-22.624,0L192,169.376l-36.688-36.688
                        c-6.24-6.248-16.384-6.248-22.624,0c-6.248,6.248-6.248,16.376,0,22.624L169.376,192l-36.688,36.688
                        c-6.248,6.248-6.248,16.376,0,22.624C135.808,254.44,139.904,256,144,256s8.192-1.56,11.312-4.688L192,214.624l36.688,36.688
                        C231.816,254.44,235.904,256,240,256s8.184-1.56,11.312-4.688c6.248-6.248,6.248-16.376,0-22.624L214.624,192z"
					/>
				</g>
			</g>
		</symbol>

		<symbol id="pending" viewBox=" 0 0 24 24">
			<path
				d="m20.25 18.25h-2.75c-.414 0-.75-.336-.75-.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v2h2c.414 0 .75.336.75.75s-.336.75-.75.75z"
			/>
			<path
				d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
			/>
			<path
				d="m9.25 15h-7c-1.24 0-2.25-1.009-2.25-2.25v-9.5c0-1.241 1.01-2.25 2.25-2.25h17.5c1.24 0 2.25 1.009 2.25 2.25v6c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-6c0-.414-.337-.75-.75-.75h-17.5c-.413 0-.75.336-.75.75v9.5c0 .414.337.75.75.75h7c.414 0 .75.336.75.75s-.336.75-.75.75z"
			/>
			<path
				d="m11.001 9.827c-.404 0-.809-.092-1.177-.277l-9.407-4.658c-.371-.184-.522-.633-.339-1.005.184-.371.631-.524 1.005-.339l9.41 4.66c.311.155.706.155 1.011.001l9.413-4.661c.374-.186.821-.032 1.005.339.184.372.032.821-.339 1.005l-9.41 4.66c-.366.183-.769.275-1.172.275z"
			/>
		</symbol>

		<symbol id="menu" viewBox="0 0 512 512">
			<g>
				<path d="M0,382.5h459v-51H0V382.5z M0,255h459v-51H0V255z M0,76.5v51h459v-51H0z" />
			</g>
		</symbol>

		<symbol id="trash" viewBox="0 0 365.744 365.744">
			<g>
				<path
					d="M88.309,339.074c1.64,15.621,15.131,27.282,30.825,26.645H250.79c15.981,0.636,29.705-11.258,31.347-27.167
                    l21.42-223.608H62.186L88.309,339.074z"
				/>
				<path
					d="M342.742,47.026h-97.176V30.307c0.58-16.148-12.041-29.708-28.188-30.288c-0.53-0.019-1.061-0.024-1.591-0.014h-65.829
                    c-16.156-0.299-29.494,12.555-29.793,28.711c-0.01,0.53-0.005,1.061,0.014,1.591v16.718H23.003
                    c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449h319.739c5.771,0,10.449-4.678,10.449-10.449
                    S348.512,47.026,342.742,47.026z M224.668,30.307v16.718h-83.592V30.307c-0.589-4.579,2.646-8.768,7.225-9.357
                    c0.549-0.071,1.104-0.086,1.656-0.047h65.829c4.605-0.326,8.603,3.143,8.929,7.748C224.755,29.203,224.739,29.758,224.668,30.307
                    z"
				/>
			</g>
		</symbol>

		<symbol id="login" viewBox="0 0 480.509 480.509">
			<g>
				<path
					d="M418.119,331.046c4.094,4.374,7.766,8.186,10.996,11.416c3.237,3.238,6.714,6.376,10.427,9.422
                    c3.71,3.042,6.427,4.568,8.135,4.568c3.241,0,9.517-4.661,18.843-13.99c9.328-9.321,13.989-15.604,13.989-18.842
                    c0-1.523-2.714-5.421-8.138-11.704c-5.421-6.276-12.364-13.702-20.838-22.271c-8.473-8.565-16.703-16.744-24.694-24.55
                    c-8.001-7.81-15.8-15.373-23.417-22.703c-7.611-7.327-11.992-11.56-13.135-12.703c-1.902-1.902-4.093-2.853-6.563-2.853
                    c-3.237,0-9.521,4.661-18.843,13.988c-9.328,9.322-13.989,15.605-13.989,18.843c0,1.711,1.522,4.421,4.568,8.138
                    c3.046,3.71,6.188,7.187,9.421,10.424c3.23,3.23,7.047,6.899,11.42,10.992c4.377,4.093,6.848,6.423,7.423,6.995l-27.408,27.404
                    L254.954,222.268c24.94-33.498,37.408-68.236,37.408-104.211c0-31.024-9.761-56.293-29.263-75.801
                    c-19.512-19.511-44.778-29.265-75.805-29.265c-30.454,0-60.244,9.042-89.363,27.119c-29.121,18.083-52.727,41.686-70.808,70.808
                    C9.042,140.038,0,169.828,0,200.28c0,31.029,9.753,56.286,29.265,75.803c19.511,19.517,44.777,29.27,75.801,29.27
                    c35.976,0,70.71-12.467,104.212-37.407l191.574,191.579c5.332,5.328,11.796,7.994,19.417,7.994c7.991,0,15.704-3.72,23.12-11.14
                    c7.426-7.426,11.143-15.129,11.143-23.127c0-7.617-2.666-14.092-7.994-19.417l-62.811-62.811l27.405-27.404
                    C411.699,324.195,414.033,326.666,418.119,331.046z M221.556,161.458c-10.656,10.657-23.601,15.987-38.828,15.987
                    c-7.996,0-15.896-1.812-23.7-5.43c3.617,7.808,5.426,15.706,5.426,23.7c0,15.229-5.327,28.171-15.987,38.828
                    c-10.66,10.655-23.606,15.986-38.831,15.986c-15.227,0-28.168-5.325-38.828-15.986c-10.657-10.657-15.987-23.599-15.987-38.828
                    c0-15.227,5.327-28.171,15.987-38.828C81.464,146.23,94.409,140.9,109.636,140.9c7.992,0,15.893,1.809,23.695,5.424
                    c-3.616-7.804-5.424-15.706-5.424-23.699c0-15.227,5.327-28.171,15.987-38.828c10.66-10.657,23.604-15.987,38.831-15.987
                    c15.227,0,28.171,5.327,38.828,15.987c10.657,10.66,15.987,23.601,15.987,38.828C237.539,137.852,232.209,150.797,221.556,161.458z
                    "
				/>
			</g>
		</symbol>

		<symbol id="log-out" viewBox="0 0 512 512">
			<g>
				<g>
					<g>
						<path
							d="M510.371,226.513c-1.088-2.603-2.645-4.971-4.629-6.955l-63.979-63.979c-8.341-8.32-21.824-8.32-30.165,0
                            c-8.341,8.341-8.341,21.845,0,30.165l27.584,27.584H320.013c-11.797,0-21.333,9.557-21.333,21.333s9.536,21.333,21.333,21.333
                            h119.168l-27.584,27.584c-8.341,8.341-8.341,21.845,0,30.165c4.16,4.181,9.621,6.251,15.083,6.251s10.923-2.069,15.083-6.251
                            l63.979-63.979c1.984-1.963,3.541-4.331,4.629-6.955C512.525,237.606,512.525,231.718,510.371,226.513z"
						/>
						<path
							d="M362.68,298.667c-11.797,0-21.333,9.557-21.333,21.333v106.667h-85.333V85.333c0-9.408-6.187-17.728-15.211-20.437
                            l-74.091-22.229h174.635v106.667c0,11.776,9.536,21.333,21.333,21.333s21.333-9.557,21.333-21.333v-128
                            C384.013,9.557,374.477,0,362.68,0H21.347c-0.768,0-1.451,0.32-2.197,0.405c-1.003,0.107-1.92,0.277-2.88,0.512
                            c-2.24,0.576-4.267,1.451-6.165,2.645c-0.469,0.299-1.045,0.32-1.493,0.661C8.44,4.352,8.376,4.587,8.205,4.715
                            C5.88,6.549,3.939,8.789,2.531,11.456c-0.299,0.576-0.363,1.195-0.597,1.792c-0.683,1.621-1.429,3.2-1.685,4.992
                            c-0.107,0.64,0.085,1.237,0.064,1.856c-0.021,0.427-0.299,0.811-0.299,1.237V448c0,10.176,7.189,18.923,17.152,20.907
                            l213.333,42.667c1.387,0.299,2.795,0.427,4.181,0.427c4.885,0,9.685-1.685,13.525-4.843c4.928-4.053,7.808-10.091,7.808-16.491
                            v-21.333H362.68c11.797,0,21.333-9.557,21.333-21.333V320C384.013,308.224,374.477,298.667,362.68,298.667z"
						/>
					</g>
				</g>
			</g>
		</symbol>

		<symbol id="settings" viewBox="0 0 512 512">
			<g>
				<g>
					<path
						d="M326.9,67.166c6.7-14.5,13.5-28.9,20.6-44.2c-78.6-33.1-155.2-31.5-228.4,12.1C48.3,77.166,9.9,141.166,0,223.566
                c16.8,1.5,32.7,2.9,48.6,4.2C57.3,103.266,194.9,7.766,326.9,67.166z"
					/>
					<path
						d="M484.7,202.466c-15.8,2.7-31.5,5.5-47.9,8.3c8.5,57-3.3,108.4-38.1,153.4c-34.6,44.8-81,69.4-137.7,75.7
                c1.4,16.1,2.8,31.8,4.1,47.5C383.8,483.166,511,363.966,484.7,202.466z"
					/>
					<path
						d="M47.9,262.066c-15.1,1.3-30.8,2.7-46.5,4c3.7,99.5,90.5,211.5,221.4,222.1c1.4-16.1,2.8-31.9,4.1-47.2
                C124.5,423.866,65.2,364.566,47.9,262.066z"
					/>
					<path
						d="M398.9,125.566c12.2,15.8,21.6,33.2,28.9,52.4c15.2-5.5,30.2-11,45.9-16.7c-18.1-48.2-47.6-86.6-89.5-116.7
                c-9.3,13.3-18.5,26.3-28,39.9C372.4,96.366,386.8,109.866,398.9,125.566z"
					/>
					<path
						d="M267.2,384.166v-19.1c16.3-3.1,32.1-9.6,46.3-19.4l13.6,13.6c5.1,5,13.2,5,18.3,0l14.1-14.1c5-5.1,5-13.2,0-18.3
                l-13.8-13.8c9.6-14.2,15.9-30,18.9-46.2h19.6c7.1,0,12.9-5.8,12.9-12.9v-20c0-7.1-5.8-12.9-12.9-12.9h-19.7
                c-3.1-16.2-9.5-31.8-19.1-45.9l14-14c5-5.1,5-13.2,0-18.3l-14.3-14.2c-5.1-5-13.2-5-18.3,0l-14,14c-14.1-9.6-29.8-16-46-19.1
                v-19.7c0-7.1-5.8-12.9-12.9-12.9h-20c-7.1,0-12.9,5.8-12.9,12.9v19.6c-16.2,3-32,9.4-46.2,19l-13.7-13.6c-5.1-5-13.2-5-18.3,0
                l-14.1,14.2c-5,5.1-5,13.2,0,18.3l13.6,13.6c-9.8,14.2-16.2,30-19.3,46.3h-19.1c-7.1,0-12.9,5.8-12.9,12.9v20
                c0,7.1,5.8,12.9,12.9,12.9h19c3.1,16.4,9.5,32.3,19.2,46.5l-13.2,13.5c-5,5.1-5,13.2,0,18.3l14.1,14.1c5.1,5,13.2,5,18.3,0
                l13.4-13.4c14.3,9.7,30.2,16.1,46.6,19.2v19c0,7.1,5.8,12.9,12.9,12.9h20C261.4,397.066,267.2,391.266,267.2,384.166z
                 M197,291.266c-26-26-26.1-68.2-0.1-94.2s68.2-26.1,94.2-0.1s26.1,68.2,0.1,94.2C265.3,317.166,223.1,317.266,197,291.266z"
					/>
				</g>
			</g>
		</symbol>

		<symbol id="super" viewBox="0 0 512.002 512.002">
			<g>
				<path
					d="M509.866,153.61l-64-85.333c-2.01-2.688-5.177-4.271-8.531-4.271H74.668c-3.354,0-6.521,1.583-8.531,4.271l-64,85.333
                    c-3.042,4.042-2.813,9.677,0.542,13.458l245.333,277.323c2.021,2.292,4.938,3.604,7.99,3.604s5.969-1.313,7.99-3.604
                    l245.333-277.323C512.678,163.287,512.907,157.652,509.866,153.61z M65.938,132.548c-13.574,19.354-19.693,35.688-21.661,49.354
                    l-19.859-22.448l55.583-74.115h51.306C105.472,95.5,81.432,110.453,65.938,132.548z M256.001,421.235l-51.217-57.896
                    c1.301,0.008,2.458,0.031,3.832,0.031c28.697,0,72.668-2.1,109.608-12.471L256.001,421.235z M363.043,295.871
                    c-7.279,26.766-57.576,39.647-109.365,44.185c-5.249-19.63-21.328-62.716-61.677-62.716c-24.883,0-39.147,11.04-46.346,19.16
                    l-38.059-43.021c9.447,1.669,19.793,2.527,30.957,2.527c0.042,0,0.083,0,0.125,0c164.125-0.25,211.708,17.052,222.719,31.458
                    C363.459,290.152,363.938,292.589,363.043,295.871z M440.528,212.647c-23.241-41.868-89.513-41.974-184.527-41.974
                    c-64,0-64-21.615-64-32c0-24.219,11.115-53.125,64.042-53.333h1.583c85.135,0.333,104.802,54.438,105.583,56.688
                    c1.448,4.365,5.531,7.313,10.125,7.313h64c5.896,0,10.667-4.771,10.667-10.667v-31.999l39.583,52.78L440.528,212.647z"
				/>
			</g>
		</symbol>

		<symbol id="contact" viewBox="0 0 511.874 511.874">
			<g>
				<path
					d="M490.114,12.813H132.568c-12.012,0.014-21.746,9.748-21.76,21.76v98.62l-16.35-24.525
				c-7.484-11.289-22.535-14.676-34.133-7.68l-33.638,20.224c-11.016,6.464-19.097,16.946-22.545,29.244
				c-12.271,44.681-3.166,121.66,109.824,234.667C203.821,474.885,270.816,499.06,316.99,499.06
				c10.69,0.049,21.339-1.34,31.659-4.13c12.293-3.448,22.775-11.518,29.252-22.519l20.198-33.673
				c6.968-11.589,3.584-26.609-7.68-34.091l-80.546-53.692c-11.049-7.308-25.859-4.905-34.031,5.521l-23.45,30.148
				c-2.451,3.226-6.897,4.166-10.445,2.21l-4.463-2.458c-14.686-8.004-32.964-17.971-69.879-54.886
				c-3.994-3.994-7.612-7.731-11.008-11.307h333.517c11.982,0.009,21.713-9.676,21.76-21.658V34.573
				C511.86,22.561,502.126,12.827,490.114,12.813z M229.318,401.362l4.335,2.381c10.897,6.093,24.614,3.266,32.213-6.639
				l23.45-30.148c2.666-3.396,7.49-4.179,11.093-1.801l80.546,53.692c3.659,2.439,4.759,7.321,2.5,11.093l-20.198,33.673
				c-4.218,7.233-11.071,12.553-19.123,14.848c-40.337,11.093-110.933,1.707-218.078-105.446S9.56,195.273,20.627,154.97
				c2.293-8.051,7.61-14.903,14.839-19.123l33.673-20.207c3.773-2.254,8.652-1.155,11.093,2.5l53.717,80.546
				c2.382,3.602,1.599,8.43-1.801,11.093l-30.157,23.458c-9.903,7.597-12.731,21.311-6.639,32.205l2.389,4.335
				c8.533,15.65,19.14,35.123,57.805,73.779C194.212,382.213,213.677,392.828,229.318,401.362z M494.808,298.526
				c-0.028,2.567-2.127,4.627-4.693,4.608H141.203c-11.083-12.674-20.64-26.604-28.476-41.506l-2.458-4.48
				c-1.96-3.54-1.022-7.982,2.202-10.428l30.157-23.458c10.43-8.17,12.833-22.982,5.521-34.031l-20.275-30.43V34.573
				c-0.014-1.249,0.476-2.451,1.359-3.334c0.883-0.883,2.085-1.373,3.334-1.359h357.547c1.249-0.014,2.451,0.476,3.334,1.359
				c0.883,0.883,1.373,2.085,1.359,3.334V298.526z"
				/>
				<path
					d="M460.725,52.323l-142.618,108.16c-4.035,2.932-9.499,2.932-13.534,0L162.008,52.323
				c-3.756-2.849-9.111-2.113-11.959,1.643c-2.849,3.756-2.113,9.111,1.643,11.959l142.583,108.151
				c10.144,7.494,23.989,7.494,34.133,0L471.034,65.925c1.805-1.368,2.992-3.398,3.299-5.642c0.307-2.244-0.29-4.518-1.661-6.321
				C469.824,50.213,464.478,49.48,460.725,52.323z"
				/>
				<path
					d="M238.517,174.793l-87.415,93.611c-3.214,3.447-3.025,8.848,0.422,12.062c3.447,3.214,8.848,3.025,12.062-0.422
				l87.416-93.653c2.888-3.484,2.553-8.617-0.762-11.698C246.924,171.612,241.78,171.656,238.517,174.793z"
				/>
				<path
					d="M384.728,174.793c-3.214-3.447-8.614-3.637-12.062-0.422c-3.447,3.214-3.637,8.614-0.422,12.062l87.39,93.611
				c3.214,3.447,8.614,3.637,12.062,0.422c3.447-3.214,3.637-8.614,0.422-12.062L384.728,174.793z"
				/>
			</g>
		</symbol>

		<symbol id="log" viewBox="0 0 128 128">
			<g>
				<path
					d="m22.974 76.206a1.75 1.75 0 0 0 -3.489.284c.391 4.8 2.071 9.932 5.615 9.932a1.772 1.772 0 0 0 1.779-1.746 1.721 1.721 0 0 0 -1.72-1.746c-.433-.161-1.811-2.13-2.185-6.724z"
				/>
				<path
					d="m19.763 69.441a1.75 1.75 0 1 0 3.451.584c.522-3.084 1.47-4.5 1.887-4.751.68.393 2.209 3.364 2.209 8.814a1.75 1.75 0 0 0 3.5 0c0-5.941-1.787-12.334-5.71-12.334-2.565 0-4.511 2.802-5.337 7.687z"
				/>
				<path
					d="m32.028 64.1a1.75 1.75 0 0 0 3.293-1.186 19.941 19.941 0 0 0 -3.484-6.255 1.75 1.75 0 1 0 -2.66 2.275 16.742 16.742 0 0 1 2.851 5.166z"
				/>
				<path
					d="m16.78 81.98a30.738 30.738 0 0 1 -1.007-7.966c0-9.517 4.092-17.557 8.936-17.557a1.75 1.75 0 0 0 0-3.5c-6.974 0-12.436 9.249-12.436 21.057a34.216 34.216 0 0 0 1.127 8.874 1.75 1.75 0 1 0 3.38-.908z"
				/>
				<path
					d="m37.146 74.014a1.75 1.75 0 0 0 -3.5 0c0 9.516-4.093 17.556-8.937 17.556-2.229 0-4.477-1.829-6.169-5.018a1.75 1.75 0 0 0 -3.092 1.641c2.353 4.435 5.642 6.877 9.261 6.877 6.974 0 12.437-9.249 12.437-21.056z"
				/>
				<path d="m64 54.03h-15.016a1.75 1.75 0 0 0 0 3.5h15.016a1.75 1.75 0 0 0 0-3.5z" />
				<path d="m81.646 54.03h-6.078a1.75 1.75 0 0 0 0 3.5h6.078a1.75 1.75 0 0 0 0-3.5z" />
				<path
					d="m113.7 80.51a1.751 1.751 0 0 0 -1.75-1.75h-40.787a1.75 1.75 0 0 0 0 3.5h40.789a1.75 1.75 0 0 0 1.748-1.75z"
				/>
				<path
					d="m118.7 93.816a41.4 41.4 0 0 0 4.74-19.8 46.546 46.546 0 0 0 -.331-5.521 1.64 1.64 0 0 0 -.035-.3 39.921 39.921 0 0 0 -3.774-12.847 1.723 1.723 0 0 0 -.352-.684c-3.7-6.967-9.341-11.431-15.657-11.431h-19.168l-11.889-16.789c-.023-.033-.053-.058-.078-.089-1.6-2.072-5.517-3.149-9.409-3.149-5.051 0-10.165 1.807-10.165 5.261a3.294 3.294 0 0 0 .244 1.246l4.515 13.52h-32.632c-11.109 0-20.152 13.809-20.152 30.781s9.04 30.78 20.152 30.78h78.582c5.961 0 11.324-3.977 15.017-10.282a1.74 1.74 0 0 0 .392-.696zm-57.3-49.463-3.766-11.291a19.246 19.246 0 0 0 5.113.666c.074 0 .148 0 .222-.005l6.731 13.847c-4.394-.246-7.437-1.407-8.295-3.217zm12.179 3.193-6.906-14.2a10.826 10.826 0 0 0 4.737-2l9.872 13.942c-.719.759-3.417 1.953-7.698 2.258zm-10.832-20.84c3.959 0 6.216 1.174 6.619 1.739 0 .007.007.015.012.022v.005c-.328.494-2.133 1.479-5.292 1.706a1.825 1.825 0 0 0 -.245.009c-.355.021-.711.041-1.1.041-4.005 0-6.276-1.2-6.639-1.761.369-.557 2.64-1.761 6.645-1.761zm-54.69 47.308c0-15.043 7.47-27.281 16.652-27.281s16.653 12.238 16.653 27.281a38.37 38.37 0 0 1 -4.27 18.216 1.765 1.765 0 0 0 -.278.489c-3.038 5.274-7.339 8.575-12.1 8.575-9.187 0-16.657-12.238-16.657-27.28zm95.234 27.28h-69.265a24.061 24.061 0 0 0 5.362-6.224h24.612a1.75 1.75 0 0 0 0-3.5h-22.752a39.5 39.5 0 0 0 2.873-9.31h10.328a1.75 1.75 0 0 0 0-3.5h-9.827a47.018 47.018 0 0 0 .24-4.746c0-1.251-.055-2.482-.15-3.694h48.327a1.75 1.75 0 0 0 0-3.5h-48.739c-1.374-8.728-5.187-16.02-10.274-20.087h24.737c1.968 2.837 6.418 4.387 12.737 4.387.335 0 .671-.008 1.007-.018h.011.018c5.195-.163 10.436-1.558 11.975-4.368h18.78c4.368 0 8.346 2.774 11.32 7.3h-22.369a1.75 1.75 0 1 0 0 3.5h24.306a36.406 36.406 0 0 1 2.8 9.29h-9.848a1.75 1.75 0 0 0 0 3.5h10.287c.1 1.209.156 2.44.156 3.694a38.836 38.836 0 0 1 -3.92 17.556h-13.117a1.75 1.75 0 0 0 0 3.5h10.961c-2.878 3.883-6.567 6.22-10.576 6.22z"
				/>
				<path d="m93.039 91.57h-6.51a1.75 1.75 0 0 0 0 3.5h6.51a1.75 1.75 0 0 0 0-3.5z" />
			</g>
		</symbol>

		<symbol id="add" viewBox="0 0 512 512">
			<g>
				<g>
					<g>
						<path
							d="M156,256c0,11.046,8.954,20,20,20h60v60c0,11.046,8.954,20,20,20s20-8.954,20-20v-60h60c11.046,0,20-8.954,20-20
              c0-11.046-8.954-20-20-20h-60v-60c0-11.046-8.954-20-20-20s-20,8.954-20,20v60h-60C164.954,236,156,244.954,156,256z"
						/>
						<path
							d="M160.406,61.8l25.869-10.716c10.204-4.228,15.051-15.927,10.823-26.132c-4.228-10.205-15.926-15.054-26.132-10.823
              l-25.869,10.716c-10.204,4.228-15.051,15.927-10.823,26.132C138.488,61.148,150.168,66.038,160.406,61.8z"
						/>
						<path
							d="M256,0c-11.046,0-20,8.954-20,20s8.954,20,20,20c119.378,0,216,96.608,216,216c0,119.378-96.608,216-216,216
              c-119.378,0-216-96.608-216-216c0-11.046-8.954-20-20-20s-20,8.954-20,20c0,141.483,114.497,256,256,256
              c141.483,0,256-114.497,256-256C512,114.517,397.503,0,256,0z"
						/>
						<path
							d="M93.366,113.165l19.799-19.799c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L65.081,84.88
              c-7.811,7.811-7.811,20.475,0,28.285C72.89,120.974,85.555,120.976,93.366,113.165z"
						/>
						<path
							d="M24.952,197.099c10.227,4.236,21.914-0.642,26.132-10.823l10.716-25.87c4.228-10.205-0.619-21.904-10.823-26.132
              c-10.207-4.227-21.904,0.619-26.132,10.823l-10.716,25.869C9.901,181.172,14.748,192.871,24.952,197.099z"
						/>
					</g>
				</g>
			</g>
		</symbol>
	</defs>
</svg>
