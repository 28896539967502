import { Injectable, Injector } from '@angular/core';
import { tap, switchMap } from 'rxjs/operators';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { environment } from 'src/environments/environment';

enum ErrorCodes {
	'SESSION_EXPIRED' = 'Auth 010 --- Authentication session has been expired by user.',
}

// the endpoints in this array don't need to have the authorization + access token header attached
const NonAuthorizationEndpoints = [
	`${environment.SUPER_SERVICE_ENDPOINT}/super-auth/login`,
	`${environment.SUPER_SERVICE_ENDPOINT}/super-auth/send-login-codes`,
	`${environment.SUPER_SERVICE_ENDPOINT}/super-auth/refresh-tokens`,
];

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
	private authService!: AuthService;

	constructor(private inj: Injector) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return new Observable((observer: any) => {
			setTimeout(() => {
				this.authService = this.inj.get(AuthService);
				observer.complete();
			});
		}).pipe(
			switchMap(() => {
				// getting access token
				const accessToken = this.authService.accessToken.value;

				// if access about to expire, we refresh it
				if (accessToken?.exp ?? 0 + 10 * 1000 < Date.now()) {
					// if refresh token expired, we log the user out on the client and return an error
					const refreshToken = this.authService.activeSession.value;
					if (refreshToken?.exp ?? 0 * 1000 < Date.now()) {
						this.authService.logOutClient();
						return throwError(() => 'Session expired, please log in again.');
					} else {
						// if the refresh token has not expired, use it to refresh the tokens
						return this.authService
							.refreshTokens(this.authService.rawTokens.value?.refreshToken as string)
							.pipe(
								switchMap(() => {
									return this.handleRequest(request, next);
								}),
							);
					}
				} else {
					// if the access token has not expired, just handle the request
					return this.handleRequest(request, next);
				}
			}),
		);
	}

	private handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// cloning request
		let clonedRequest = request.clone();

		// authorizing headers
		if (!request.headers.has('authorization') && this.shouldAttachAuthorization(request.url)) {
			clonedRequest = request.clone({
				headers: new HttpHeaders().append(
					'authorization',
					`Bearer ${this.authService.rawTokens.value?.accessToken}`,
				),
			});
		}

		return next.handle(clonedRequest).pipe(
			tap({
				error: (err) => {
					if (err.status === ErrorCodes.SESSION_EXPIRED) {
						this.authService.logOutClient();
						err.message = 'Session expired, please log in again.';
					}
				},
			}),
		);
	}

	// checks if we don't need to attach the authorization header to this endpoint
	private shouldAttachAuthorization(url: string): boolean {
		let blacklisted = true;

		for (const endpoint of NonAuthorizationEndpoints) {
			if (url.startsWith(endpoint)) {
				blacklisted = false;
				break;
			}
		}

		return blacklisted;
	}
}
