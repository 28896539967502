import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';

export type Icons =
	| 'lock'
	| 'paper-plane'
	| 'warning'
	| 'apple-logo'
	| 'save'
	| 'qrcode'
	| 'person'
	| 'refresh'
	| 'copy'
	| 'download'
	| 'stop'
	| 'undo'
	| 'search'
	| 'switch'
	| 'edit'
	| 'check'
	| 'upload-image'
	| 'cross'
	| 'pending'
	| 'trash'
	| 'login'
	| 'log-out'
	| 'settings'
	| 'super'
	| 'contact'
	| 'log'
	| 'add';

@Component({
	selector: 'icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
	public absUrl = window.location.href.split('#')[0];

	@Input() name!: Icons;
	@Input() size!: string;
	@Input() color!: string;
	@Input() width?: string;
	@Input() height?: string;
	@Input() margin?: string;
	@Input() padding?: string;
	constructor(
		private location: Location,
		private ref: ChangeDetectorRef,
	) {
		this.location.onUrlChange(() => {
			this.absUrl = window.location.href.split('#')[0];
			this.ref.detectChanges();
		});
	}

	// This doesn't seeem to cause the recalculation of the colors on change detection
	// but I'll keep an eye on it
	public get_color_code(color: string): string {
		switch (color) {
			case 'primary':
				return '#22bc94';
			case 'accent':
				return '#e35100';
			case 'warn':
				return '#ff0044';
			default:
				return color;
		}
	}
}
