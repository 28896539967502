<svg
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	[style.width]="width ? width : size"
	[style.height]="height ? height : size"
	[style.margin]="margin ? margin : '0'"
	[style.padding]="padding ? padding : '0'"
>
	<use
		[style.fill]="color ? get_color_code(color) : ''"
		[attr.xlink:href]="absUrl + '#' + name"
	></use>
</svg>
