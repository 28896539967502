import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	myLogin!: FormGroup;
	hide = true;

	constructor(private fb: FormBuilder) {}
	ngOnInit() {
		this.myLogin = this.fb.group({
			email: ['', [Validators.required, Validators.email, Validators.min(1), Validators.max(28)]],
			password: [
				'',
				[Validators.required, Validators.pattern(''), Validators.min(1), Validators.max(28)],
			],
		});
		this.myLogin.valueChanges.subscribe(console.log);
	}
	get email() {
		return this.myLogin.get('email');
	}
	get password() {
		return this.myLogin.get('password');
	}
}

// import { Component } from '@angular/core';

// @Component({
// 	selector: 'app-login',
// 	templateUrl: './login.component.html',
// 	styleUrls: ['./login.component.scss'],
// })
// export class LoginComponent {
// 	toggleLogin() {}
// 	toggleForgotPassword() {}
// 	passwordVisible = false;
// 	togglePasswordVisibility() {
// 		this.passwordVisible = !this.passwordVisible;
// 	}
// }
