import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom, tap, Observable, throwError } from 'rxjs';
import jwt from 'jwt-decode';
import { environment } from 'src/environments/environment';
import type { JwtPayload } from 'jwt-decode';
import { Router } from '@angular/router';

export interface IRefreshToken extends JwtPayload, ITokenPayload {
	sessionId: string;
	type: 'REFRESH_TOKEN';
}

export interface ITokenPayload {
	userId: string;
	email?: string;
	phone?: string;
	firstName?: string;
	lastName?: string;
	deviceId?: string;
	superadmin?: boolean;
}

export interface IRefreshToken extends JwtPayload, ITokenPayload {
	sessionId: string;
	type: 'REFRESH_TOKEN';
}

export interface IAccessToken extends JwtPayload, ITokenPayload {
	sessionId: string;
	type: 'ACCESS_TOKEN';
}

export interface ISendLoginCodesInput {
	email: string;
	phone: string;
	password: string;
}

export interface ILoginInput extends ISendLoginCodesInput {
	phoneCode: string;
	emailCode: string;
}

export interface ITokenResponse {
	accessToken: string;
	refreshToken: string;
}

enum AuthCache {
	DECODED_REFRESH_TOKEN = 'DECODED_REFRESH_TOKEN',
	DECODED_ACCESS_TOKEN = 'DECODED_ACCESS_TOKEN',
	RAW_TOKENS = 'RAW_TOKENS',
}

@Injectable({
	providedIn: 'root',
})
export class AuthService implements OnInit {
	public activeSession = new BehaviorSubject<IRefreshToken | null>(
		JSON.parse(localStorage.getItem(AuthCache.DECODED_REFRESH_TOKEN) ?? 'null'),
	);

	public accessToken = new BehaviorSubject<IAccessToken | null>(
		JSON.parse(localStorage.getItem(AuthCache.DECODED_ACCESS_TOKEN) ?? 'null'),
	);

	// tokens, also our user state
	public rawTokens = new BehaviorSubject<ITokenResponse | null>(
		JSON.parse(localStorage.getItem(AuthCache.RAW_TOKENS) ?? 'null'),
	);

	// dependencies
	constructor(
		protected readonly http: HttpClient,
		protected readonly router: Router,
	) {}

	ngOnInit(): void {
		// continously caching refresh token
		this.activeSession.subscribe((value) => {
			localStorage.setItem(AuthCache.DECODED_REFRESH_TOKEN, JSON.stringify(value));
		});

		// continously caching access token
		this.accessToken.subscribe((value) => {
			localStorage.setItem(AuthCache.DECODED_ACCESS_TOKEN, JSON.stringify(value));
		});

		// continously caching tokens
		this.rawTokens.subscribe((value) => {
			localStorage.setItem(AuthCache.RAW_TOKENS, JSON.stringify(value));
		});
	}

	public refreshTokens(refreshToken: string): Observable<ITokenResponse> {
		// requesting new tokens
		return this.http
			.post<ITokenResponse>(`${environment.SUPER_SERVICE_ENDPOINT}/super-auth/refresh-tokens`, {
				refreshToken,
			})
			.pipe(
				tap((newTokens) => {
					// pushing new tokens
					this.rawTokens.next(newTokens);

					// pushing new decoded session
					this.activeSession.next(jwt(newTokens.refreshToken) as IRefreshToken);
				}),
			);
	}

	/** calls backend to trigger sending codes required for login */
	public sendLoginCodes(input: ISendLoginCodesInput): Observable<any> {
		return this.http.post(
			`${environment.SUPER_SERVICE_ENDPOINT}/super-auth/send-login-codes`,
			input,
		);
	}

	/**calls backend to complete login then updates local state with decoded tokens */
	public login(input: ILoginInput): Observable<ITokenResponse> {
		// making http login request
		return this.http
			.post<ITokenResponse>(`${environment.SUPER_SERVICE_ENDPOINT}/super-auth/login`, input)
			.pipe(
				tap((tokens) => {
					// pushing new tokens
					this.rawTokens.next(tokens);
					// pushing new decoded session
					this.activeSession.next(jwt(tokens.refreshToken) as IRefreshToken);
				}),
			);
	}

	public logOut(): Observable<any> {
		// sending logout request
		return this.http.get(`${environment.SUPER_SERVICE_ENDPOINT}/super-auth/log-out`).pipe(
			tap(() => {
				this.logOutClient();
			}),
		);
	}

	/** logs the user out on the client side */
	public logOutClient(): void {
		// removing tokens from local state
		this.rawTokens.next(null);
		this.activeSession.next(null);
		this.accessToken.next(null);

		// navigating to homepage
		this.router.navigate(['/']);
	}
}
