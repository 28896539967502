import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './components/icon/icon.component';
import { IconDefinitionsComponent } from './components/icon-definitions/icon-definitions.component';

@NgModule({
	declarations: [IconComponent, IconDefinitionsComponent],
	imports: [CommonModule],
	exports: [IconComponent, IconDefinitionsComponent],
})
export class SharedModule {}
